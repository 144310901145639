export class AristotleSearchResults extends HTMLElement {
    static get observedAttributes() {
        return ['query', 'current_so_id']
    }
    constructor() {
        super()
        this.filters = {}
        this.results = []
        this.selectedFilters = {
            last_updated: '',
            type: '',
            stewardship_organisations: '',
        }
        this.render()
        this.fetchComponentSetup()
    }

    connectedCallback() {
        this.setupListeners()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'query') {
                this.resetFilters()
                this.fetchSearchResults()
            }

        if (name === 'current_so_id' && oldValue !== newValue) {
            this.selectedFilters.stewardship_organisations = newValue  // Update selected filter
        }
    }

    resetFilters() {
        // Reset selectedFilters and re-render filters
        const currentSoId = this.getAttribute('current_so_id')
        this.selectedFilters = {
            last_updated: '',
            type: 'all',
            stewardship_organisations: '',
        }
        if (currentSoId && currentSoId !== '') {
            this.selectedFilters.stewardship_organisations = Number(currentSoId)
        }
        this.renderFilters()
    }

    async fetchComponentSetup() {
        try {
            const response = await fetch("/api/internal/omnisearch/component-setup/")
            const data = await response.json()
            this.filters = data.filters
            this.renderFilters()
        } catch (error) {
            console.error("Error fetching component setup:", error)
        }
    }

    async fetchSearchResults() {
        const query = this.getAttribute('query')
        let { last_updated, type, stewardship_organisations } = this.selectedFilters

        if (type === "help") {
            stewardship_organisations = ''
        }

        const url = `/api/internal/omnisearch/search/?q=${encodeURIComponent(query || '')}&category=${type || ''}&mq=${last_updated || ''}&sa=${stewardship_organisations || ''}`
        try {
            const resultsContainer = this.querySelector("#results")
            resultsContainer.innerHTML = '<div class="search-results-loader"></div>'  // Clear previous results and show loader
            this.toggleLoader(true)  // Show loader
            const response = await fetch(url)
            const data = await response.json()
            this.results = data.results
            this.renderResults()
        } catch (error) {
            console.error("Error fetching search results:", error)
        } finally {
            this.toggleLoader(false)  // Hide loader after results are rendered
        }
    }

    toggleLoader(show) {
        const loader = this.querySelector(".search-results-loader")
        if (loader) loader.style.display = show ? "block" : "none"
    }

    setupListeners() {
        // Listener for radio buttons
        const filtersContainer = this.querySelector("#filters")
        filtersContainer.addEventListener('change', (event) => {
            const { name, value } = event.target
            if (name in this.selectedFilters) {
                this.selectedFilters[name] = value
                this.fetchSearchResults()
            }
        })
        // Listener for the "Clear Filters" button
        const clearFiltersButton = this.querySelector("#clear-filters")
        clearFiltersButton.addEventListener('click', (event) => {
            event.preventDefault()
            const previousSOId = this.getAttribute('current_so_id')
            this.selectedFilters = {
                last_updated: '',
                type: 'all',
                stewardship_organisations: '',
            }

            this.setAttribute('current_so_id', '')
            this.renderFilters()
            this.fetchSearchResults()
            this.setAttribute('current_so_id', previousSOId)
        })
    }

    render() {
        this.innerHTML = `
        <div class="search-results-container">
            <div class="search-results-filters" id="filters">
                <!-- Filters will be dynamically inserted here -->
                <div id="filters-radio-button-sections"></div>
                <div class="filter-footer">
                    <button id="clear-filters" type="button">Clear Filters</button>
                    <a id="search-help" href="https://help.aristotlemetadata.com/finding-and-viewing-metadata/search/using-omnisearch-to-find-registry-content" target="_blank">How to search</a>
                </div>
            </div>
            <div class="search-results-results" id="results">
                <div class="search-results-loader"></div> <!-- Loader element -->
            </div>
        </div>
        `
    }

    renderFilters() {
        const filtersContainer = this.querySelector("#filters-radio-button-sections")
        filtersContainer.innerHTML = `
          <div class="filter-section">
            <h4 class="result-header">Stewardship Organisations</h4>
            <div class="org-filter"></div>
          </div>
          <div class="filter-section">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <h4 class="result-header">Category</h4>
              <a href="https://help.aristotlemetadata.com/finding-and-viewing-metadata/search/using-omnisearch-to-find-registry-content" target="_blank" style="text-decoration: none;">
                <i class="fa fa-question" aria-hidden="true" style="font-size: 1.4rem; color: #337ab7;"></i>
              </a>
            </div>
            <div class="type-filter"></div>
          </div>
          <div class="filter-section">
            <h4 class="result-header">Last Updated</h4>
            <div class="last-updated-filter"></div>
          </div>
        `
        this.renderFilterSection(this.querySelector('.last-updated-filter'), this.filters.last_updated, 'last_updated')
        this.renderFilterSection(this.querySelector('.type-filter'), this.filters.type, 'type')
        this.renderFilterSection(this.querySelector('.org-filter'), this.filters.stewardship_organisations, 'stewardship_organisations')
    }

    renderFilterSection(container, items, filterType) {
        container.innerHTML = ''
        const currentSOId = this.getAttribute('current_so_id')

        items.forEach(item => {
            const label = document.createElement('label')
            label.setAttribute('class', 'label-style')
            let isChecked = false
            if (filterType === 'last_updated') {
                isChecked = item.label === 'Anytime' && item.value === ''
            } else if (filterType === 'type') {
                isChecked = item.label === 'All' && item.value === 'all'
            } else if (filterType === 'stewardship_organisations') {
                isChecked = (currentSOId === '' && item.value === '') ||
                            (currentSOId !== '' && item.value === Number(currentSOId))
            }

            label.innerHTML = `<input type="radio" name="${filterType}" value="${item.value}" ${isChecked ? 'checked' : ''}>  ${item.label}`
            container.appendChild(label)
        })
    }

    formatDate(dateString) {
        const date = new Date(dateString)
        if (isNaN(date)) {
            return null
        }

        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()

        return `${day}/${month}/${year}`
    }

    navigateToMoreResults() {
        const query = this.getAttribute('query')
        let { last_updated, type, stewardship_organisations } = this.selectedFilters
        const url = `/search?q=${encodeURIComponent(query || '')}&category=${type || ''}&mq=${last_updated || ''}&sa=${stewardship_organisations || ''}`
        window.location.href = url
    }

    highlightQuery(text, query) {
        if (!query) return text

        // Escape all special characters in query before using in RegExp
        const escapedQuery = query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')

        const regex = new RegExp(`(${escapedQuery})`, 'gi')

        return text.replace(regex, `<strong>$1</strong>`)
    }

    renderResults() {
        const query = this.getAttribute('query')
        const resultsContainer = this.querySelector("#results")
        resultsContainer.innerHTML = ""
        const filteredResults = this.results

        if (filteredResults.length > 0) {
            resultsContainer.innerHTML += `<h4 class="result-header">Showing ${filteredResults.length} results</h4>`
        } else {
            resultsContainer.innerHTML += `
                <div class="search-results-no-results">
                    <span class="question-mark">?</span>
                    <h4>We couldn't find anything matching your search.</h4>
                    <div>Try again with a different term.</div>
                </div>
            `
            return
        }

        filteredResults.forEach(result => {
            const listItem = document.createElement('a')
            listItem.href = result.url
            let badgeText = ''
            badgeText = result.type.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')
            const matchingOrg = this.filters.stewardship_organisations.find(org => org.value === result.stewardship_organisation)
            const highlightedTitle = this.highlightQuery(result.title, query)
                listItem.innerHTML = `
                <div style="display: flex; justify-content: space-between; align-items: flex-start;">
                    <div style="flex: 1; display: flex; align-items: center;">
                        ${badgeText ? `<span class="badge">${badgeText}</span> ` : ''}
                        <img src="${matchingOrg && matchingOrg.profile_picture_url ? matchingOrg.profile_picture_url : ''}"
                            alt="Icon"
                            style="width: 30px; height: 30px; margin-right: 5px; ${matchingOrg && matchingOrg.profile_picture_url ? '' : 'visibility: hidden;'}">
                    </div>
                    <div style="flex: 4;">
                        <div>${highlightedTitle}</div>
                        <div>
                            <small><span style="margin-right: 6px">${result.type.name}</span>${matchingOrg && matchingOrg.label ? `${matchingOrg.label}` : ''}</small>
                            <span style="display: inline-block"><small>Last modified: ${result.modified ? this.formatDate(result.modified) : "N/A"}</small></span>
                        </div>
                    </div>
                </div>
            `
            listItem.classList.add('search-results-result-item')

            resultsContainer.appendChild(listItem)
        })
            const seeMoreButton = document.createElement('button')
            seeMoreButton.textContent = 'See More Results'
            seeMoreButton.classList.add('search-see-more-results-button')
            seeMoreButton.addEventListener('click', (event) => {
                event.preventDefault()
                this.navigateToMoreResults()
            })
            resultsContainer.appendChild(seeMoreButton)
    }

}

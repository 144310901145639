/* globals CKEDITOR */
import { isFormstageElement } from 'src/lib/html.js'
import { addPlugins } from 'src/lib/ckeditor-plugins.js'

// Ckeditor imports
import 'ckeditor4'
// Import non standard plugins, so they are able to be enabled by individual editors
import 'ckeditor4/plugins/justify/plugin.js'

// Add custom plugins
addPlugins(CKEDITOR)

// CKEDITOR config
let CKEDITOR_SPECIAL_CHARS = [
    '&euro;', '&lsquo;', '&rsquo;', '&ldquo;', '&rdquo;', '&ndash;', '&mdash;', '&iexcl;',
    '&cent;', '&pound;', '&curren;', '&yen;', '&brvbar;', '&sect;', '&uml;', '&copy;', '&ordf;',
    '&laquo;', '&not;', '&reg;', '&macr;', '&deg;', '&sup2;', '&sup3;', '&acute;', '&micro;',
    '&para;', '&middot;', '&cedil;', '&sup1;', '&ordm;', '&raquo;', '&frac14;', '&frac12;',
    '&frac34;', '&iquest;', '&Agrave;', '&Aacute;', '&Acirc;', '&Atilde;', '&Auml;', '&Aring;',
    '&AElig;', '&Ccedil;', '&Egrave;', '&Eacute;', '&Ecirc;', '&Euml;', '&Igrave;', '&Iacute;',
    '&Icirc;', '&Iuml;', '&ETH;', '&Ntilde;', '&Ograve;', '&Oacute;', '&Ocirc;', '&Otilde;',
    '&Ouml;', '&times;', '&Oslash;', '&Ugrave;', '&Uacute;', '&Ucirc;', '&Uuml;', '&Yacute;',
    '&THORN;', '&szlig;', '&agrave;', '&aacute;', '&acirc;', '&atilde;', '&auml;', '&aring;',
    '&aelig;', '&ccedil;', '&egrave;', '&eacute;', '&ecirc;', '&euml;', '&igrave;', '&iacute;',
    '&icirc;', '&iuml;', '&eth;', '&ntilde;', '&ograve;', '&oacute;', '&ocirc;', '&otilde;',
    '&ouml;', '&divide;', '&oslash;', '&ugrave;', '&uacute;', '&ucirc;', '&uuml;', '&yacute;',
    '&thorn;', '&yuml;', '&OElig;', '&oelig;', '&#372;', '&#374', '&#373', '&#375;', '&sbquo;',
    '&#8219;', '&bdquo;', '&hellip;', '&trade;', '&#9658;', '&bull;', '&rarr;', '&rArr;',
    '&hArr;', '&diams;', '&asymp;', '&cong;', '&asymp;', '&ne;', '&equiv;', '&le;', '&ge;'
]

let config = {
    'toolbar': [
        {'name': 'clipboard', 'items': ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo', ]},
        {'name': 'basicstyles', 'items': ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-', 'RemoveFormat']},
        {'name': 'links', 'items': ['Link', 'Unlink']},
        {'name': 'paragraph', 'items': ['NumberedList', '-', 'BulletedList', '-', 'Blockquote', '-', 'Indent',
                                        'Outdent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight']},
        {'name': 'insert', 'items': ['Image', 'Table', 'HorizontalRule', 'SpecialChar']},
        {'name': 'aristotletoolbar', 'items': ['Glossary']},
        {'name': 'document', 'items': ['Maximize', 'Source']},
     ],
    'width': "",
    'disableNativeSpellChecker': false,
    'extraPlugins': 'aristotle_glossary,justify,divarea,contextmenu,tabletools,tableresize',
    'customConfig': '',
    'removePlugins': 'liststyle,tableselection',
    'extraAllowedContent': 'a[data-aristotle-concept-id]; *{text-decoration,font-style,color,font-weight}; span',
    "filebrowserBrowseUrl": '/ckeditor/browse/',
    "filebrowserUploadUrl": '/ckeditor/upload/',
    "specialChars": CKEDITOR_SPECIAL_CHARS,
}


let compact_config = {
    'toolbar': [
        {'name': 'basicstyles', 'items': ['Bold', 'Italic', 'Underline', '-', 'RemoveFormat']},
        {'name': 'links', 'items': ['Link', 'Unlink']},
        {'name': 'paragraph', 'items': ['NumberedList', 'BulletedList', '-' ]},
        {'name': 'document', 'items': ['Maximize']},
     ],
    'width': "",
    'height': "3em",
    'disableNativeSpellChecker': false,
    'extraPlugins': 'aristotle_glossary,justify,divarea,contextmenu,tabletools,tableresize',
    'customConfig': '',
    'removePlugins': 'liststyle,tableselection',
    'extraAllowedContent': 'a[data-aristotle-concept-id]; *{text-decoration,font-style,color,font-weight}; span',
    "filebrowserBrowseUrl": '/ckeditor/browse/',
    "filebrowserUploadUrl": '/ckeditor/upload/',
    "specialChars": CKEDITOR_SPECIAL_CHARS,
}

export {config, compact_config}

export function initCKEditor() {
    let textareas = document.querySelectorAll('textarea[data-type=ckeditortype]')
    for (let te of textareas) {
        // If not inside a formstage
        if (!isFormstageElement(te)) {
            initCKEditorFromTextarea(te)
        }
    }
}

export function initCKEditorFromTextarea(textarea) {
    let editor = null
    let processed = textarea.getAttribute('data-processed')
    if (processed == 0 || processed == null) {
        // If data-config not provided use config
        let config_to_load = JSON.parse(textarea.getAttribute('data-config'));
        if (config_to_load === undefined) {
            config_to_load = config;
        }

        editor = CKEDITOR.replace(textarea, config_to_load)
        textarea.setAttribute('data-processed', 1)
    }
    return editor
}

export function disOrenableCKEditor(fieldId, disableOrEnable) {
    if (disableOrEnable == 'd') {
        CKEDITOR.instances[fieldId].setReadOnly(true)
    } else {
        if (CKEDITOR.instances[fieldId]) {
            CKEDITOR.instances[fieldId].setReadOnly(false)
        }
    }
}

// ReInitialize ckeditor instances
export function reinitCKEditors(form) {
    // If function recieved jQuery object get first Element
    if (form instanceof jQuery) {
        form = form.get(0)
    }

    // Remove any already initialised ckeditor instances
    for (let cke of form.querySelectorAll('div.cke, span.cke')) {
        // Can't use .remove() due to IE compatibility
        cke.parentNode.removeChild(cke)
    }

    // Initialise ckedior instances from text boxes
    for (let textarea of form.querySelectorAll('textarea[data-type=ckeditortype]')) {
        let config = JSON.parse(textarea.getAttribute('data-config'));
        CKEDITOR.replace(textarea.id, config)
        textarea.setAttribute('data-processed', 1)
    }
}
